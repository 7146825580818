import { Application } from "@hotwired/stimulus"
import { registerControllers } from "stimulus-vite-helpers"
import Sortable from "@stimulus-components/sortable"

const application = Application.start()
const controllers = import.meta.glob("./**/*_controller.js", { eager: true })

registerControllers(application, controllers)
application.register("sortable", Sortable)

function registerPhlexControllers() {
  const controllers = import.meta.glob(
    [
      "./../../views/**/controller.js",
      "./../../../packages/**/app/views/**/controller.js"
    ],
    { eager: true }
  )

  for (let path in controllers) {
    let module = controllers[path]

    let name = path
      .match(/views\/(.+)\/controller\.js$/)[1]
      .replaceAll("_", "-")
      .replaceAll("/", "--")

    application.register(name, module.Controller)
  }
}

registerPhlexControllers()

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

export { application }
