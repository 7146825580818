import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["phase", "template"]

  addPhase() {
    const nextPhase = this.nextPhase
    if (nextPhase === null) return

    const newContent = this._makePhase(nextPhase)
    this.insertAfter(newContent, this.lastRow)
  }

  insertAfter(newNode, existingNode) {
    existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling);
  }

  _makePhase(phase) {
    const template = this.templateTarget
    const newContent = template.content.cloneNode(true)
    const th = newContent.querySelector("th")
    const tr = newContent.querySelector("tr")
    tr.dataset.phase = phase
    th.innerText = phase
    return newContent
  }

  get lastRow() {
    const tbody = this.element.querySelector("tbody")
    return tbody.lastElementChild
  }

  get phases() {
    return Array
      .from(this.phaseTargets)
      .map(row => row.dataset.phase)
      .sort()
  }

  get currentPhase() {
    return this.phases[this.phases.length - 1]
  }

  get nextPhase() {
    const nextPhase = this.currentPhase.charCodeAt(0) + 1
    if (nextPhase > "Z".charCodeAt(0)) return null

    return String.fromCharCode(nextPhase)
  }
}
