import Sortable from "@stimulus-components/sortable"
import { patch } from "@rails/request.js"

export default class Controller extends Sortable {
  static targets = ["phase"]

  connect() {
    this.disabled = []
    this.draggedOverPhase = null
    super.connect()
  }

  onMove(evt, _originalEvent) {
    const related = evt.related

    if (this._didMovePhases(related)) {
      if (this.phaseTargets.includes(related)) {
        this.draggedOverPhase = related
      } else {
        throw new Error("The dragged over phase is not a valid phase")
      }

      return 1
    }
  }

  onStart(evt) {
    this.disabled = this.element.querySelectorAll('.sort-filtered')
    this.currentIndex = evt.oldIndex
  }

  async onUpdate({ item, newIndex }) {
    if (!item.dataset.sortableUpdateUrl) return

    const data = this._formParams(newIndex)

    return await patch(
      item.dataset.sortableUpdateUrl,
      {
        body: data,
        responseKind: this.responseKindValue
      }
    )
  }

  _formParams(index) {
    const position = index + 1
    const data = new FormData()

    let phaseToMoveTo = null

    if (this.draggedOverPhase) {
      const isPlacedAbove = index < this.currentIndex
      const indexOfPhase = this.phaseTargets.indexOf(this.draggedOverPhase)

      if (isPlacedAbove) {
        if (indexOfPhase === 0) {
          phaseToMoveTo = this.phaseTargets[0]
        } else {
          phaseToMoveTo = this.phaseTargets[indexOfPhase - 1]
        }
      } else {
        phaseToMoveTo = this.phaseTargets[indexOfPhase]
      }
    }


    data.append(this._attributeName(this.paramNameValue), position)

    if (phaseToMoveTo) {
      data.append(this._attributeName("phase"), phaseToMoveTo.dataset.phase)
    }

    return data
  }

  _attributeName(name) {
    return this.resourceNameValue ? `${this.resourceNameValue}[${name}]` : name
  }

  _didMovePhases(phase_element) {
    for (const item of this.disabled) {
      if (phase_element === item) {
        return true
      }
    }

    return false
  }

  get defaultOptions() {
    return {
      onStart: this.onStart.bind(this),
      onMove: this.onMove.bind(this),
      filter: ".sort-filtered",
    }
  }
}
